import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchFacultiesDetails } from "../Components/api";
import LoaderComponent from "../Components/LoaderComponent";
import DepartmentsList from "./DepartmentsList";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Image,
} from "semantic-ui-react";
import { formatResponseDataKey } from "../Components/utils";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";
import DashPannelOne from "./DashPannelOne";

export default function FacultyDetails(props) {
  const { state } = useLocation();

  const [facultyDetails, setDetails] = useState();
  const [isLoading, setLoading] = useState(false);
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const { instituteid: facultyid } = useParams();
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    let facultyId = -1;
    if (state && state.facultyid) {
      facultyId = state.facultyid;
    } else if (facultyid) {
      facultyId = facultyid;
    }
    setLoading(true);
    // make API call to fetch institute data
    fetchFacultiesDetails(facultyId)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          // console.log(data);
          setDetails(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Failed to fetch institute details: ", err);
        setLoading(false);
      });
  }, [facultyid, state]);

  const renderfacultyDetails = () => {
    if (facultyDetails) {
      return (
        <Segment basic>
          {facultyDetails["logo_photo"] ? (
            <div
              style={{
                // border: "1px solid brown",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                src={
                  facultyDetails["logo_photo"]
                    ? facultyDetails["logo_photo"]
                    : "https://react.semantic-ui.com/images/wireframe/image.png"
                }
                // wrapped
                size="medium"
                // style={{
                //   border: "2px solid green",
                // }}
                // ui={false}
              />
            </div>
          ) : null}
          <Divider hidden />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {Object.keys(facultyDetails).map((key) => {
              if (
                key === "id" ||
                key === "name" ||
                key === "parent" ||
                key === "departments" ||
                key === "logo_photo"
              )
                return null; // don't show id or courses data
              if (key === "faculties") {
                return (
                  <p key={key}>
                    <strong>{formatResponseDataKey(key)}</strong> -{" "}
                    <span>
                      {facultyDetails[key] && facultyDetails[key]["name"]
                        ? facultyDetails[key]["name"]
                        : "Not Available "}
                    </span>
                  </p>
                );
              }
              // console.log(key, formatResponseDataKey(key), instituteDetails[key])
              return (
                <p key={key}>
                  <strong>{formatResponseDataKey(key)}</strong> -{" "}
                  <span>
                    {facultyDetails[key]
                      ? facultyDetails[key]
                      : "Not Available "}
                  </span>
                </p>
              );
            })}
          </div>
        </Segment>
      );
    } else return <h3>Faculty data not found</h3>;
  };

  if (isLoading) {
    return <LoaderComponent loadingText="Loading faculty details ... " />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <DashPannelOne visibility={true}/>
            <Header as="h1" className="heading-font">
              <Icon name="university" />
              <Header.Content>
                {facultyDetails && facultyDetails.name
                  ? facultyDetails.name + " details"
                  : "Faculty details"}
              </Header.Content>
            </Header>
            <Divider />

            {renderfacultyDetails()}
            {facultyDetails &&
            facultyDetails.departments &&
            facultyDetails.departments.length > 0 ? (
              <DepartmentsList list={facultyDetails.departments} dVisibility={true} />
            ) : (
              <Header as="h3">No departments found</Header>
            )}
          </Segment>
        </div>
      </Container>
    </>
  );
}
