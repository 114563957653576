import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { pwbsFeedback } from './api';
import {getCurrentDate} from '../utils'
export default function Feedback(props) {
    const [isLoading, setIsLoading] = useState(false);
    const handleFeedbackSubmit = async () => {
        setIsLoading(true);
        if (!props.counsellorId || !props.counseleeId || !props.counseleeEmail) {
            setIsLoading(false)
            console.log(props.counsellorId);
            console.log(props.counseleeId);
            console.log(props.counseleeEmail);
            
            
            console.error('Error: Required fields are missing');
            return null
        }
        // Create a new FormData object
        const formData = new FormData();
        formData.append('counsellor_id', props.counsellorId);
        formData.append('counselee_id', props.counseleeId);
        formData.append('counselee_email', props.counseleeEmail);
        formData.append('session_date', getCurrentDate())

        try {
            // Pass the FormData to the pwbsFeedback function
            await pwbsFeedback(formData);
            // Handle success
            console.log('Feedback submitted successfully');
        } catch (error) {
            // Handle error
            console.error('Error submitting feedback:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Button
                primary
                disabled={isLoading}
                animated
                onClick={handleFeedbackSubmit}
                loading={isLoading}  // Add loading spinner
            >
                <Button.Content visible>Request Feedback</Button.Content>
                <Button.Content hidden>
                    <Icon name="send" />
                </Button.Content>
            </Button>
        </div>
    );
}