import { useState, useEffect } from "react";
import { fetchDepartmentList } from "./api";
import LoaderComponent from "./LoaderComponent";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Table,
  Input,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import Navbar from "./Navbar";
import SearchModal from "./Modals/SearchModal";
import DashPannelOne from "./DashPannelOne";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";

export default function DepartmentsList(props) {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [departmentsList, setList] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dashPannelOneVisibility = props.dVisibility ? false : true;


  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (props.list && props.list.length > 0) {
      setList(props.list);
      setFilteredDepartments(props.list);
    } else {
      setLoading(true);
      fetchDepartmentList()
        .then((res) => res.json())
        .then((data) => {
          if (data && data.length > 0) {
            setList(data);
            setFilteredDepartments(data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Failed to fetch departments list: ", err);
          setLoading(false);
        });
    }
  }, [props.list]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = departmentsList.filter((dept) =>
        dept.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredDepartments(filtered);
    } else {
      setFilteredDepartments(departmentsList);
    }
  }, [searchQuery, departmentsList]);

  if (isLoading) {
    return <LoaderComponent loadingText="Loading departments list..." />;
  }

  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <DashPannelOne visibility={dashPannelOneVisibility} />
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="building" />
              <Header.Content>Department List</Header.Content>
            </Header>
            <Divider />
            <Input
              icon="search"
              placeholder="Search departments..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fluid
              style={{ marginBottom: "20px" }}
            />
            {filteredDepartments && filteredDepartments.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "70vh",
                  overflowX: "auto",
                }}
              >
                <Table
                  celled
                  basic
                  compact
                  striped
                  textAlign="start"
                  padded
                  size="large"
                  style={{ width: "66%" }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Department Name</Table.HeaderCell>
                      {props.list ? null : (
                        <Table.HeaderCell>Parent</Table.HeaderCell>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {filteredDepartments.map((dept) => (
                      <Table.Row key={dept.id}>
                        <Table.Cell width={6}>
                          <Link
                            to={"/department/" + dept.id + "/"}
                            state={{ departmentId: dept.id }}
                          >
                            {dept.name}
                          </Link>
                        </Table.Cell>
                        {props.list ? null : (
                          <Table.Cell width={4}>
                            {dept.parent ? dept.parent : "Not Available"}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <h2>No department found</h2>
            )}
          </Segment>
        </div>
      </Container>
    </>
  );
}
