import { useState, useEffect } from "react";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Table,
  Input,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchProgramsList } from "../Components/api";
import LoaderComponent from "../Components/LoaderComponent";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import DashPannelOne from "./DashPannelOne";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";

export default function ProgramsList(props) {
  const [programsList, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [isLoading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dashPannelOneVisibility = props.dVisibility ? false : true;


  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (props && props.list && props.list.length > 0) {
      setPrograms(props.list);
      setFilteredPrograms(props.list);
    } else {
      setLoading(true);
      fetchProgramsList()
        .then((res) => res.json())
        .then((data) => {
          if (data && data.length > 0) {
            setPrograms(data);
            setFilteredPrograms(data);
          } else {
            console.error("Could not find programs data in the response");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Could not fetch programs list: ", err);
          setLoading(false);
        });
    }
  }, [props]);

  const DEBOUNCE_DELAY = 300; 

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchQuery) {
        const normalizedQuery = searchQuery.toLowerCase().replace(/[^a-z0-9]/gi, ''); // Remove all non-alphanumeric characters
        const filtered = programsList.filter((prg) => {
          const normalizedProgramName = prg.name.toLowerCase().replace(/[^a-z0-9]/gi, ''); // Normalize program name
          return normalizedProgramName.includes(normalizedQuery);
        });
        setFilteredPrograms(filtered);
      } else {
        setFilteredPrograms(programsList);
      }
    }, DEBOUNCE_DELAY);
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, programsList]);
  
  
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <DashPannelOne visibility={dashPannelOneVisibility} />
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="book" />
              <Header.Content>Programs List</Header.Content>
            </Header>
            <Divider />
            <Input
              icon="search"
              placeholder="Search programs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fluid
              style={{ marginBottom: "20px" }}
            />
            {isLoading ? ( 
              <LoaderComponent />
            ) : filteredPrograms && filteredPrograms.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "70vh",
                  overflowX: "auto",
                }}
              >
                <Table
                  celled
                  basic
                  compact
                  striped
                  textAlign="start"
                  padded
                  size="large"
                  style={{ width: "60%" }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Program Name</Table.HeaderCell>
                      <Table.HeaderCell>Program Type</Table.HeaderCell>
                      {props.list ? null : (
                        <Table.HeaderCell>Department</Table.HeaderCell>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {filteredPrograms.map((prg) => (
                      <Table.Row key={prg.id}>
                        <Table.Cell>
                          <Link
                            to={"/program/" + prg.id + "/"}
                            state={{ programId: prg.id }}
                          >
                            {prg.name}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          {prg.program_type ? prg.program_type : "Not Available"}
                        </Table.Cell>
                        {props.list ? null : (
                          <Table.Cell>
                            {prg.department ? prg.department : "Not Available"}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <h2>No program found</h2>
            )}
          </Segment>
        </div>
      </Container>
    </>
  );
  
}
