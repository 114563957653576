import React, { useState } from "react"; // Import useState to manage hover state
import { Header } from "semantic-ui-react"; // Import necessary components
import "semantic-ui-css/semantic.min.css"; // Import Semantic UI CSS
import LibraryImage from "../../Resources/cb.jpg"; // Import the image
import NavbarWrapper from "../Template/NavbarWrapper";

const Library = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const getLinkStyle = (linkIndex) => ({
    color: hoveredLink === linkIndex ? "red" : "#007bff", // Change color based on hover state
    textDecoration: "underline",
  });

  return (
    <NavbarWrapper>
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={LibraryImage}
          alt="Central Library BHU"
          style={{
            width: "100%", // Keep image responsive
            height: "auto",
            maxHeight: "500px",
            objectFit: "cover", // Ensure the image is cropped correctly
            borderRadius: "10px", // Rounded corners for better aesthetics
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow for depth
          }}
        />
      </div>

      {/* Header for Central Library */}
      <Header
        as="h2"
        textAlign="center"
        style={{
          fontFamily: '"Times New Roman", Georgia, serif',
          fontSize: "32px", // Make the title stand out
          color: "#333", // Dark text color for contrast
          marginBottom: "20px", // Add some space below the title
        }}
      >
        Central Library
      </Header>

      {/* Paragraph description with link to Central Library */}
      <p
        style={{
          textAlign: "left", // Align text to the left
          fontSize: "16px", // Slightly larger text for better readability
          lineHeight: "1.6", // Better line spacing for readability
          color: "#444", // Softer black for less strain on eyes
          marginBottom: "20px", // Add spacing between paragraphs
        }}
      >
        The Banaras Hindu University Library is one of the largest university
        library systems in India.{" "}
        <a
          href="https://www.bhu.ac.in/Site/UnitHomeTemplate/1_3240_4531_Main-Site-Central-Library"
          target="_blank" // Opens the link in a new tab
          rel="noopener noreferrer" // Security best practice
          style={getLinkStyle(1)} // Apply inline link style
          onMouseEnter={() => setHoveredLink(1)} // Set hover state to true
          onMouseLeave={() => setHoveredLink(null)} // Reset hover state
        >
          Visit the Central Library Website
        </a>
        .
      </p>

      {/* BHU e-Library */}
      <p
        style={{
          textAlign: "left", // Align text to the left
          fontSize: "16px",
          lineHeight: "1.6",
          color: "#444",
          marginBottom: "20px",
        }}
      >
        BHU e-Library service portal is a portal for providing search and access
        facility to the electronic resources accessible to the university users,
        including the eSS resources provided by consortiums like INFLIBNET,
        ERMED, etc. The BHU e-Library service portal is available at the link
        given below:{" "}
        <a
          href="https://bhuelibrary.in/#/home"
          target="_blank"
          rel="noopener noreferrer"
          style={getLinkStyle(2)} // Apply inline link style
          onMouseEnter={() => setHoveredLink(2)} // Set hover state to true
          onMouseLeave={() => setHoveredLink(null)} // Reset hover state
        >
          BHU e-Library
        </a>
        .
      </p>

      {/* OPAC information */}
      <Header
        as="h3"
        textAlign="center"
        style={{
          fontFamily: '"Times New Roman", Georgia, serif',
          fontSize: "18px",
          color: "#333",
          marginBottom: "10px",
          marginTop: "40px",
        }}
      >
        Online Public Access Catalogue (OPAC)
      </Header>
      <p
        style={{
          textAlign: "left", // Align text to the left
          fontSize: "16px",
          lineHeight: "1.6",
          color: "#444",
          marginBottom: "20px",
        }}
      >
        Online Public Access Catalogue (OPAC) is the interface to search the
        library holdings of the university. Windows interface can be used
        through the OPAC computers installed in the libraries of the university.
        Web interface of OPAC can be used right from here. Users may use the
        simple search or the advanced search to look for their required document
        in the library and their status, i.e. whether available or issued.
      </p>
      <p
        style={{
          textAlign: "left", // Align text to the left
          fontSize: "16px",
          lineHeight: "1.6",
          color: "#444",
          marginBottom: "20px",
        }}
      >
        <strong>OPAC (while using BHU-WIFI):</strong>{" "}
        <a
          href="http://10.105.160.9/webopac/"
          target="_blank"
          rel="noopener noreferrer"
          style={getLinkStyle(3)} // Apply inline link style
          onMouseEnter={() => setHoveredLink(3)} // Set hover state to true
          onMouseLeave={() => setHoveredLink(null)} // Reset hover state
        >
          Access OPAC Here
        </a>
        .
      </p>
      <p
        style={{
          textAlign: "left", // Align text to the left
          fontSize: "16px",
          lineHeight: "1.6",
          color: "#444",
          marginBottom: "5vh", // Remove margin from the last paragraph
        }}
      >
        <strong>OPAC (while using other net):</strong>{" "}
        <a
          href="http://14.139.41.119/webopac"
          target="_blank"
          rel="noopener noreferrer"
          style={getLinkStyle(4)} // Apply inline link style
          onMouseEnter={() => setHoveredLink(4)} // Set hover state to true
          onMouseLeave={() => setHoveredLink(null)} // Reset hover state
        >
          Access OPAC Here
        </a>
        .
      </p>
    </NavbarWrapper>
  );
};

export default Library;
